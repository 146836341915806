// eslint-disable-next-line object-curly-newline
import { mdiFileOutline, mdiHomeOutline, mdiAccountOutline } from '@mdi/js'

export default [
  {
    title: 'Home',
    icon: mdiHomeOutline,
    to: 'home',
    resource: 'User',
    action: 'read',
  },
  {
    title: 'Sites',
    icon: mdiFileOutline,
    to: 'sites',
    resource: 'Admin',
    action: 'read',
  },
  {
    title: 'Users',
    icon: mdiAccountOutline,
    to: 'user-list',
    resource: 'Admin',
    action: 'read',
  },
]
