import axios from '@axios'

export default {
  namespaced: true,
  state: {
    list: [],
    INV: [],
    GME: [],
    BAT: [],
  },
  getters: {
    getInverters(state) {
      return state.devices.INV
    },
  },
  mutations: {
    UPDATE_DEVICES(state, devices) {
      state.list = devices
    },
    UPDATE_INV(state, value) {
      state.INV = value !== undefined ? value : !state.INV
    },
    UPDATE_GME(state, value) {
      state.GME = value !== undefined ? value : !state.GME
    },
    UPDATE_BAT(state, value) {
      state.BAT = value !== undefined ? value : !state.BAT
    },
  },
  actions: {
    fetchDevices({ commit }, { siteId }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/device/devicelist?site_id=${siteId}`)
          .then(response => {
            if (typeof response.data.data.devices !== 'undefined') {
              commit('UPDATE_DEVICES', response.data.data.devices)

              const inverters = response.data.data.devices
                .filter(device => device.device_type === 'INV')
                .map(obj => ({
                  device_id: obj.device_id,
                  device_name: obj.device_name_nixt,
                  site_id: obj.site_id,
                  brand: obj.brand,
                }))
              commit('UPDATE_INV', inverters)

              const meters = response.data.data.devices
                .filter(device => device.device_type === 'GME')
                .map(obj => ({
                  device_id: obj.device_id,
                  device_name: obj.device_name_nixt,
                  site_id: obj.site_id,
                  brand: obj.brand,
                }))
              commit('UPDATE_GME', meters)

              return this.state
            }
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    // eslint-disable-next-line no-unused-vars
    fetchNixtDevices({ commit }, { siteId }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/device/devicelistnixt?site_id=${siteId}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
