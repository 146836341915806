import axios from '@axios'

export default {
  namespaced: true,
  state: {
    list: [],
  },
  getters: {},
  mutations: {
    UPDATE_LIST(state, sites) {
      state.list = sites
    },
  },
  actions: {
    fetchAlarms({ commit }, { siteId }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/device/getalarm?site_id=${siteId}`)
          .then(response => {
            commit('UPDATE_LIST', [])
            if (typeof response.data.data.lastest !== 'undefined') {
              commit('UPDATE_LIST', response.data.data.lastest)
            }
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
