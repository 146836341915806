import axios from '@axios'

export default {
  namespaced: true,
  state: {
    selected: null,
    list: [],
  },
  getters: {
    siteId(state) {
      return state.selected?.site_id || null
    },
    startDate(state) {
      return state.selected?.start_date || new Date().toISOString().substr(0, 10)
    },
    firstEMI(state) {
      return state.selected?.emi_id[0] || null
    },
  },
  mutations: {
    UPDATE_SITES(state, sites) {
      state.list = sites
    },
    SET_SELECTED(state, siteId) {
      state.selected = state.list.find(item => item.site_id === siteId)
    },
  },
  actions: {
    fetchSites({ state, commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get('/device/sitelist')
          .then(response => {
            if (typeof response.data.data.sites !== 'undefined') {
              commit('UPDATE_SITES', response.data.data.sites)
              if (state.selected === null) {
                commit('SET_SELECTED', response.data.data.sites[0].site_id)
              }
            }
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchNixtSites() {
      return new Promise((resolve, reject) => {
        axios
          .get('/device/sitelistnixt')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
