// axios
import axios from 'axios'
import Vue from 'vue'
import { initialAbility } from '@/plugins/acl/config'
import router from '@/router'

const axiosIns = axios.create({
  baseURL: process.env.VUE_APP_API_URL || '',
  headers: {
    api_key: process.env.VUE_APP_API_KEY || '',
  },

  // You can add your headers here
  // ================================
  // timeout: 1000,
  // headers: {'X-Custom-Header': 'foobar'}
})

axiosIns.interceptors.request.use(
  config => {
    // Do something before request is sent

    const accessToken = localStorage.getItem('accessToken')

    // eslint-disable-next-line no-param-reassign
    if (accessToken) config.headers.Authorization = `Bearer ${accessToken}`

    return config
  },
  error => Promise.reject(error),
)

axiosIns.interceptors.response.use(
  response => response,
  error => {
    if (error.response.status === 401 || error.response.status === 422) {
      // Todo: move to store function
      localStorage.removeItem('accessToken')

      // Remove userData & Ability from localStorage
      localStorage.removeItem('userData')
      localStorage.removeItem('userAbility')

      // Reset ability
      // this.vm.$ability.$ability.update(initialAbility)

      router.push({ name: 'auth-login' })

      // store.dispatch('logout')
      // router.push('/login')
    }

    return Promise.reject(error)
  },
)

// axiosIns.interceptors.response.use(
//   error => {
//     console.log('cxxxx', error.response.status)
//     if (error.response.status === 401) {
//       console.log('401')
//       // store.dispatch('logout')
//       // router.push('/login')
//     }
//     // Promise.reject(error)
//   },
// )

Vue.prototype.$http = axiosIns

export default axiosIns
