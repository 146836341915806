import { canNavigate } from '@/plugins/acl/routeProtection'
import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: 'home',
  },
  {
    path: '/login',
    name: 'auth-login',
    component: () => import('@/views/Login.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/home',
    name: 'home',
    component: () => import('@/views/home/Home.vue'),
    meta: {
      layout: 'content',
      resource: 'User',
      action: 'read',
    },
  },
  {
    path: '/sites',
    name: 'sites',
    component: () => import('@/views/site/List.vue'),
    meta: {
      layout: 'content',
      resource: 'Admin',
    },
  },
  {
    path: '/sites/devices/:siteId',
    name: 'sites-devices',
    component: () => import('@/views/device/List.vue'),
    props: true,
    meta: {
      layout: 'content',
      resource: 'Admin',
    },
  },
  {
    path: '/sites/microgrid/:siteId',
    name: 'sites-microgrid',
    component: () => import('@/views/site/MicrogridConfig.vue'),
    props: true,
    meta: {
      layout: 'content',
      resource: 'Admin',
    },
  },
  {
    path: '/users',
    name: 'user-list',
    component: () => import('@/views/user/List.vue'),
    meta: {
      layout: 'content',
      resource: 'Admin',
    },
  },
  {
    path: '/profile/changePassword',
    name: 'changePassword',
    component: () => import('@/views/profile/ChangePassword.vue'),
    meta: {
      layout: 'content',
      resource: 'User',
    },
  },
  {
    path: '/not-authorized',
    name: 'not-authorized',
    component: () => import('@/views/NotAuthorized.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
    },
  },
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/Error404.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
    },
  },
  {
    path: '*',
    redirect: 'error-404',
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
})

// ? Router Before Each hook: https://router.vuejs.org/guide/advanced/navigation-guards.html
router.beforeEach((to, _, next) => {
  const userData = localStorage.getItem('userData')

  const isLoggedIn = userData && localStorage.getItem('accessToken') && localStorage.getItem('userAbility')

  if (!canNavigate(to)) {
    // Redirect to login if not logged in
    if (!isLoggedIn) return next({ name: 'auth-login', query: { marketplace: to.query.marketplace } })

    // If logged in => not authorized
    return next({ name: 'not-authorized' })

    // return next({ name: 'misc-not-authorized' })
  }

  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    next('/')
  }

  return next()
})

export default router
